import React, { useContext, createContext, useState } from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  useOutlet,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import "../styles/header.scss";
import Button from "components/Button";
import Breadcrumbs from "components/Breadcrumbs";
import Duration from "components/Duration";
import { useAuth } from "../hooks/useAuth";

import { pauseModeration, revalidateUser } from "api";
import { setModerationData } from "actions/global";

const DashboardNav = () => {
  return (
    <div className="dashboard-nav">
      <Link to="/dashboard/moderation">START</Link>
    </div>
  );
};

const RevalidateButton = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const moderationData = useSelector((state) => state.global.moderationData);

  return (
    <Button
      type={"black"}
      loading={loading}
      standard
      onClick={async () => {
        setLoading(true);
        const data = await revalidateUser(moderationData.session.id);

        dispatch(setModerationData(data));
        setLoading(false);
      }}
    >
      <div className={`caption white tiny`} style={{ marginRight: 26 }}>
        Validation Date:
        {moderationData?.validation_date ? (
          <>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              {moment(moderationData?.validation_date).format(
                "YYYY-MM-DD HH:mm"
              )}
            </span>
          </>
        ) : null}
      </div>
      Revalidate
    </Button>
  );
};

export default function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const moderationData = useSelector((state) => state.global.moderationData);

  return (
    <header className={["header", auth.user && "signed"].join(" ")}>
      <div className="header-left">
        <img src="https://breakfast-production.s3.eu-central-1.amazonaws.com/web/logo.svg" />

        <Breadcrumbs />
      </div>

      {/* <Routes>
        <Route path="/dashboard/moderation" element={<DashboardNav />} />
      </Routes> */}

      {moderationData?.session && (
        <div className="header-center">
          <Button
            onClick={async () => {
              await pauseModeration({
                validations: moderationData.session.validations,
              });
              navigate("/dashboard");
            }}
          >
            <Duration
              time={moderationData.session.started_at}
              type="white"
              style={{ marginRight: 26 }}
            />
            Pause moderation
          </Button>
          <RevalidateButton />
        </div>
      )}

      {auth.user && (
        <div className="header-right">
          <div
            className="logout"
            onClick={() => {
              auth.logout();
            }}
          >
            log out
          </div>
          <img src={auth.user.profile.photo_square} />
        </div>
      )}
    </header>
  );
}
